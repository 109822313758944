import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import renderHTML from 'react-render-html';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { shouldLoad } from 'universal/http-client';
import { addEventListener, removeEventListener } from 'window-or-global';
import { saveQueryParamToCookies } from 'client/utilities/saveQueryParamToCookies';
import { sanitize } from 'client-utils/utilities-html-sanitizer';
import { createCookiesFromAbTests } from 'client-utils/utilities-abtest';
import SkipToContent from 'storefront/components/SkipToContent/skipToContent';
import HeaderWrapper from 'storefront/components/Header/HeaderWrapper/HeaderWrapper';
import WelcomeMat from 'storefront/components/WelcomeMat/welcomeMat';
import WelcomeMatModal from 'client/storefront/components/WelcomeMat/welcomeMatModal';
import NavSliderContianer from 'storefront/components/Navigation/NavSlider/navSliderContainer';
import ScreenReaderAnnouncer from 'storefront/components/ScreenReaderAlert/screenReaderAnnouncer';
import Spinner from 'shared/components/Spinner/spinner';
import { ECMSlots, ECMParams } from 'ecm/constants';
import CPPixel from 'ecm/components/CPPixel/cpPixel';
import EcmGet from 'ecm/components/EcmGet';
import EcmPreviewControl from 'ecm/components/EcmPreviewControl/ecmPreviewControl';
import { loseFocus } from 'shared/components/App/app-actions';
import Modal from 'shared/components/Modal/modal';
import { getCmsGlobal, isGlobalNavUpdateToggleEnabled } from 'cms/actions';
import RenderGlobalContent from 'cms/components/renderGlobalContent';
import { getGenderWithOverride } from 'client-utils/utilities-gender';
import ProductPanelWrapper from 'client/cms/components/ProductPanel/ProductPanelWrapper';
import ScrollToTopBtn from 'cms/components/scrollToTop/ScrollToTop';
import Backdrop from '@material-ui/core/Backdrop';
import { withStyles } from '@material-ui/core/styles';
import Toggle from '../Toggle/toggle';
import PanelContainer from '../YourNeimans/PanelContainer';
import {
  domLoadCompleted,
  AB_TEST_SITE_TICKER,
  getCmsGlobalBasedOnToogle,
  openAppInstallModal,
  ABTEST_EXPOSE_TOP_NAV,
} from '../../actions/actions-page';
import Home from '../../../ecm/components/Home';
import CMSHome from '../../../cms/components/home';
import { onModalAnchorClick } from './utilities';
import Footer from '../Footer/Footer';


const LimitedBackdrop = withStyles({
  root: {
    position: 'fixed',
    zIndex: 1,
  },
})(Backdrop);

export class DumbHomeApp extends Component {
  constructor() {
    super();
    this.handleModalAnchorClick = this.handleModalAnchorClick.bind(this);
    this.ecmTickerRef = createRef();
    this.defaultTickerHeight = 60;
  }

  componentDidMount() {
    addEventListener('click', this.handleModalAnchorClick);
    addEventListener('load', this.handleLoad);

    if (this.props.useInternalAbTestService) {
      createCookiesFromAbTests(this.props.abTestResults);
    }

    this.props.showAppInstallModal && this.props.dispatch(openAppInstallModal());
  }

  componentWillUnmount() {
    removeEventListener('click', this.handleModalAnchorClick);
  }

  UNSAFE_componentWillMount() {
    const {
      location, isGlobalUpdateOn, siteTickerToggleForGenderURL, selectedGender,
    } = this.props;
    saveQueryParamToCookies(location, ECMParams.PARAM_LAUNCH);
    this.handleClickOutsideFocus = this.handleClickOutsideFocus.bind(this);
    this.handleLoad = this.handleLoad.bind(this);

    if (shouldLoad(this.props.cmsGlobalApi)) {
      const cmsGlobalType = getCmsGlobalBasedOnToogle(isGlobalUpdateOn,
        siteTickerToggleForGenderURL, selectedGender);
      this.props.dispatch(getCmsGlobal(cmsGlobalType));
    }
  }

  handleModalAnchorClick(e) {
    onModalAnchorClick(e, this.props);
  }

  handleClickOutsideFocus(e, currentComponentInFocus) {
    const component = this.container.querySelector(currentComponentInFocus);
    if (component && !component.contains(e.target)) {
      this.props.dispatch(loseFocus(currentComponentInFocus));
    }
  }

  handleLoad() {
    this.props.dispatch(domLoadCompleted());
    removeEventListener('load', this.handleLoad);
  }

  render() {
    const {
      isSliderMenuVisible,
      componentInFocus,
      session = {},
      location = {},
      modal = {},
      isInternationalToggleOn,
      isMobilePhone,
      isMenDarkModeToggle,
      hpGenderToggle,
      selectedGender,
      isGlobalUpdateOn,
      isDrawerOpen,
      oneAppBannerToggle,
      browser,
      mobileScrollMenu,
      brand,
      styleUpdateToggle,
    } = this.props;
    const styles = isSliderMenuVisible ? {
      width: '100%',
      height: '87vh',
      overflow: 'hidden',
      position: 'fixed',
      top: 0,
      left: 0,
    } : {};
    const siteTickerStyle = {
      maxHeight: '55px',
    };
    const outsideClickHandler = componentInFocus
      ? (e) => this.handleClickOutsideFocus(e, componentInFocus) : undefined;

    const isMenGender = selectedGender === 'M';
    const isDarkMode = hpGenderToggle && isMenDarkModeToggle && isMenGender;

    const tickerComponent = (
      <div className="grid-container full-bleed ecm-siteticker" style={siteTickerStyle} ref={this.ecmTickerRef}>
        {
          !isEmpty(this.props.cmsGlobal)
            ? <RenderGlobalContent cmsContent={this.props.cmsGlobal} placement="SiteTicker" />
            : <EcmGet ecmSlot={ECMSlots.SITE_TICKER} />
        }
      </div>
    );

    let appContainerPadding = mobileScrollMenu && brand === 'NM' ? 150 : 90;
    if (this.ecmTickerRef?.current) {
      appContainerPadding += this.ecmTickerRef.current.clientHeight;
    } else {
      appContainerPadding += this.defaultTickerHeight;
    }

    return (
      <div id="appContainer">
        <ScreenReaderAnnouncer />
        <div
          id="contentContainer"
          style={{ paddingTop: `${isMobilePhone ? appContainerPadding : 0}px` }}
          className="application-container"
          ref={(ref) => { this.container = ref; }}
          onClick={outsideClickHandler}
          onTouchEnd={outsideClickHandler}
          aria-hidden={modal.open || isSliderMenuVisible}
          tabIndex="-1"
        >
          <Helmet>
            {
              Boolean(this.props.canonical) && <link rel="canonical" href={`${this.props.canonical}${isMenGender ? '/mens' : ''}`} />
            }
          </Helmet>
          <Helmet>
            {
              Boolean(this.props.metaSEOOverride)
              && renderHTML(sanitize(this.props.metaSEOOverride))
            }
          </Helmet>
          <Helmet>
            {
              oneAppBannerToggle && isMobilePhone && browser === 'Safari'
               && <meta name="apple-itunes-app" content="app-id=785548638, app-argument=https://apps.apple.com/us/app/neiman-marcus/id785548638" />
            }
          </Helmet>
          {!isMobilePhone && <SkipToContent />}
          {session.personalize && <EcmPreviewControl location={location} />}

          <HeaderWrapper
            isDarkMode={isDarkMode}
            tickerComponent={tickerComponent}
            ecmTickerRef={this.ecmTickerRef}
            selectedGender={this.props.selectedGender}
          />

          <div className={classNames('grid-container full-bleed', {
            'margin-30': styleUpdateToggle && brand === 'NM' && isMobilePhone,
          })}
          >
            {isGlobalUpdateOn && isDrawerOpen && componentInFocus
              && <LimitedBackdrop open={isDrawerOpen} />}
            <div id="mainContent" style={styles}>
              <Toggle
                feature="CMS_HP_NEW"
                fallback={<Home {...this.props} />}
              >
                <CMSHome {...this.props} />
              </Toggle>

            </div>
            <Spinner />
          </div>
          <Footer />
          {
            isInternationalToggleOn ? (<WelcomeMatModal />) : (<WelcomeMat />)
          }
        </div>
        <Modal />
        <NavSliderContianer />
        <PanelContainer />
        <ProductPanelWrapper />
        <Toggle feature="COHERENT_PATH_PERSONALIZATION">
          <CPPixel />
        </Toggle>
        <ScrollToTopBtn />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const isDomestic = get(state, 'locale.countryCode') === 'US';

  return {
    session: state.session,
    device: state.device,
    pageId: state.page.pageId,
    canonical: state.page.canonical,
    metaSEOOverride: state.page.metaSEOOverride,
    isSliderMenuVisible: state.navigation.navSlider.sliderMenuVisible,
    componentInFocus: state.app.componentInFocus,
    cacheMainNavigation: state.toggles.CACHE_MAIN_NAV,
    brand: state.brand_name.env,
    modal: state.modal,
    useInternalAbTestService: state.toggles.USE_INTERNAL_ABTEST_SERVICE,
    abTestResults: state.abTests.abTestResults,
    cpToggle: state.toggles.CP_NEW,
    siteTickerToggle: state.toggles.SITE_TICKER_MODAL,
    isInternationalToggleOn: state.toggles.INTERNATIONAL,
    isMobilePhone: state.device.isMobilePhone,
    cmsGlobalApi: state.api.cms_global,
    cmsGlobal: state.cms.global,
    hpGenderToggle: get(state, 'toggles.HP_GENDER', false) && isDomestic,
    isMenDarkModeToggle: get(state, 'toggles.MEN_DARKMODE', false),
    styleUpdateToggle: get(state, 'toggles.2024_REBRAND', false) && isDomestic && get(state, 'page.pageId', '') === 'PAGE_ID_HOME',
    selectedGender: getGenderWithOverride(state),
    isDrawerOpen: get(state, 'drawerPanel.open'),
    isGlobalUpdateOn: isGlobalNavUpdateToggleEnabled(state),
    siteTickerToggleForGenderURL: get(state, `abTestsOpt.${AB_TEST_SITE_TICKER}.variation`, ''),
    oneAppBannerToggle: get(state, 'toggles.APP_BANNER', false),
    browser: get(state, 'device.browser', ''),
    showAppInstallModal: get(state, 'page.location.query.type', false) === 'CFA',
    mobileScrollMenu: (get(state, 'toggles.EXPOSE_TOP_NAV', false) || get(state, `abTestsOpt.${ABTEST_EXPOSE_TOP_NAV}.variation`, 'a') === 'b') && isDomestic && get(state, 'page.pageId', '') === 'PAGE_ID_HOME',
  };
};

export default connect(mapStateToProps)(DumbHomeApp);
